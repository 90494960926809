<template>
    <div class="lesson">
        <p class="h3">
            {{ lesson.lesson.lesson.title }}
        </p>

        <div
            v-if="
                lesson.lesson.lesson.videos.length &&
                    lesson.lesson.lesson.type === 'additional' &&
                    (lesson.homework || lesson.estimatedAll)
            "
        >
            <a
                v-for="video in lesson.lesson.lesson.videos"
                :key="video._id"
                v-if="video.additionalType === 'answer'"
                :class="
                    `lesson__video ${
                        video.hasAccessByAccessLevel ? '' : 'lesson__video--disabled'
                    }`
                "
                target="_blank"
                :href="lesson.key ? video.url + '?sn=' + lesson.key : video.url"
            >
                <iframe
                    width="100%"
                    height="376"
                    :src="lesson.key ? video.url + '?sn=' + lesson.key : video.url"
                    title="Vimeo video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </a>

            <div class="lesson__buttons">
                <StudyInfoProtector
                    v-if="isVisibleModal"
                    :onClose="() => onCloseModal()"
                />
                <ButtonVideoProblem title="трудности с воспроизведением ?" />
            </div>
        </div>

        <div v-else-if="!lesson.homework" class="profileBox profileBox--2">
            <p class="profileBox__title2 colorRed">
                Внимание!
            </p>
            <p class="profileBox__text2">
                <span>
                    К сожалению, Вы не успели дать ответ в отведённый промежуток времени.
                </span>
            </p>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
import ButtonVideoProblem from "@/views/components/ButtonVideoProblem/ButtonVideoProblem";
import StudyInfoProtector from "@/views/pages/study/studyInfoProtector/studyInfoProtector";

export default {
    title: "lesson",

    components: {
        Button,
        ButtonVideoProblem,
        StudyInfoProtector,
    },

    computed: {
        lesson: function() {
            return this.$store.getters.getLesson && this.$store.getters.getLesson.lesson;
        },
    },

    async beforeMount() {
        await this.updateLessonStore();

        if (
            this.lesson &&
            this.lesson.lesson &&
            this.lesson.lesson.lesson &&
            (this.lesson.lesson.lesson.type !== "additional" ||
                this.lesson.additionalLessonStatusByDate === "openToAnswer" ||
                this.lesson.additionalLessonStatusByDate === "close" ||
                this.lesson.additionalLessonStatusByDate === "closeEarly" ||
                this.lesson.additionalLessonStatusByDate === "unknown") &&
            !this.lesson.estimatedAll
        ) {
            this.$router.push(
                `/${this.$route.params.slug}/study/${this.$route.params.block_priority}/${this.$route.params.lesson_id}`
            );
        }
    },

    data() {
        return {
            isVisibleModal: false,
        };
    },

    methods: {
        onOpenModal() {
            this.isVisibleModal = true;
        },
        onCloseModal() {
            this.isVisibleModal = false;
        },

        async updateLessonStore() {
            const getLesson = await this.axios.get(
                "/programs/" +
                    this.$route.params.slug +
                    "/study/" +
                    this.$route.params.block_priority +
                    "/" +
                    this.$route.params.lesson_id
            );

            if (getLesson && getLesson.data && getLesson.data.result) {
                await this.$store.dispatch("setLesson", getLesson.data);
            } else {
                console.error("Ошибка при поиске урока: " + (getLesson.data.error || ""));
                await this.$store.dispatch("clearLesson");
                await this.$router.push("/" + this.$route.params.slug + "/study");

                if (getLesson.data && getLesson.data.error) {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: getLesson.data.error,
                        },
                    });
                }
            }
        },
    },
};
</script>

<style lang="scss">
@import "./correctAnswer.scss";
</style>
